import { Module } from '@/Models'
import menuModule from '@/store/menu'
import { Component, Vue } from 'vue-property-decorator'
import Navbar from '@/components/Nav/Navbar.vue'
import authModule from '@/store/auth'
authModule

@Component({
  components: {
    Navbar,
  },
})
export default class Sidebar extends Vue {
  sidebar = 'sidebar'
  closeBtn = 'mdi-menu'
  searchBtn = 'mdi mdi-magnify'
  loadModules: Array<Module> = []
  /* userName = authModule.Name
  userRole = authModule.Roles[0].name */
  mounted() {
    this.loadModules = menuModule.user_modules.map((x: any) => ({
      ...x,
      is_active: false,
    }))
  }
  openSidebar() {
    this.loadModules.forEach((element: any) => {
      element.is_active = false
    })
    if (this.sidebar !== 'sidebar open') {
      this.sidebar = 'sidebar open'
      this.closeBtn = 'mdi-menu-open'
      this.$emit('clickSidebar', true)
    } else {
      this.sidebar = 'sidebar'
      this.closeBtn = 'mdi-menu'
      this.$emit('clickSidebar', false)
    }
  }

  displayMenu(index: number) {
    if (this.sidebar == 'sidebar open') {
      if (!this.loadModules[index].is_active) {
        //se cierran todos los otros modulos
        this.loadModules.forEach((element: any) => {
          element.is_active = false
        })
        //se muestran las paginas solo del modulo seleccionado
        this.loadModules[index].is_active = true
      } else {
        this.loadModules[index].is_active = false
      }
    }
  }
  to_path(path: string) {
    if (path && this.$route.path != path && path != '#') this.$router.push(path)
  }
}
